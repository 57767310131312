import { DateTime } from 'luxon';
import React, { createContext, useState, useEffect } from 'react'
import { fetchBackend } from '../helpers/fetch';
import socket from '../helpers/Socket';
import { useAuth } from '@frontegg/react'
import { blockUserInDatabase } from '../helpers/validations';


const timezone = DateTime.now().zoneName;
export const ElementContext = createContext(null);


export const ElementContextProvider = ({children}) => {

    const { user } = useAuth();
    
    socket.emit('connection')
    socket.emit('user-connected', {user: user.email})
    socket.off('connection_qr').on('connection_qr', ({qr, tenantid}) => {
        const tenant = user.email.replace('@', '_')
        console.log(tenant, tenantid, qr)
        let QRNumber;
        esMultisesion ? QRNumber = parseInt(tenantid[tenantid.length - 1]) : QRNumber = null
        
        if( esMultisesion && (qr === 'canceled') ){
            setMultisessionModal(false)
            setCodigoQR(false)
            setQRSolicitado(false)
        } else if( esMultisesion && (`${tenant}${QRNumber}` === tenantid) ){
            setCodigoQR(qr)
        } else {
            console.log('QR disponible para ' + tenantid)
        }
    })
    socket.off('connection_status').on('connection_status', async({tenantid}) => {
        if(esMultisesion){
            await getUserInfo_multiSession(tenantid)
            setMultisessionModal(false)
        } else {
            await getUserInfo()
        }
    })
    socket.off('client_disconnected').on('client_disconnected', async({ok, tenantid}) => {
        const tenant = user.email.replace('@', '_')
        if(tenant && tenantid.includes(tenant) && (tenantid.length === (tenant.length + 1))){
            const filtereduserList = multiSessionUsuarioEmisor.filter( user => user.tenantSession !== tenantid )
            setMultiSessionUsuarioEmisor(filtereduserList)
        } else if(tenant && (tenant === tenantid)){
            console.log('Desconectando WhatsApp de ' + tenantid);
            setCodigoQR(false)
            setIsAuthenticated(false)
            setQRSolicitado(false)
            setRemoveSchedule(true)
        }
    })
    
    const [esMultisesion, setEsMultisesion] = useState( window.location.href.includes('multisesion') ? true : false )
    const [inputList, setInputList] = useState([{numero: ''}]);
    const [activeList, setActiveList] = useState(null)
    const [authenticated, setIsAuthenticated] = useState(false)
    const [changesApplied, setChangesApplied] = useState(false)
    const [codigoQR, setCodigoQR] = useState(false)
    const [multiSessionQR, setMultiSessionQR] = useState([]) // Array de QR
    const [confirm, setConfirm] = useState(false)
    const [confirmDeleteContactModal, setConfirmDeleteContactModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmRemoveTemplateModal, setConfirmRemoveTemplateModal] = useState(false)
    const [delayMsg, setDelayMsg] = useState('')
    const [infoToShow, setInfoToShow] = useState([])
    const [isActive, setIsActive] = useState(null)
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [listNames, setListNames] = useState([])
    const [madeByUser, setMadeByUser] = useState(user?.email)
    const [message, setMessage] = useState('')
    const [messageWithTemplate, setMessageWithTemplate] = useState('')
    const [messagesToShow, setMessagesToShow] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [nameActive, setNameActive] = useState('')
    const [nombreEmisor, setNombreEmisor] = useState('')
    const [numeroEmisor, setNumeroEmisor] = useState('')
    const [oscillationMsg, setOscillationMsg] = useState('')
    const [returnToQRModal, setReturnToQRModal] = useState(false)
    const [rowSelected, setRowSelected] = useState(false)
    const [scheduleExecutedToShow, setScheduleExecutedToShow] = useState([])
    const [scheduleMessage, setScheduleMessage] = useState('')
    const [scheduleModal, setScheduleModal] = useState(false)
    const [scheduleSMSModal, setScheduleSMSModal] = useState(false)
    const [scheduleToShow, setScheduleToShow] = useState([])
    const [selectedGroupList, setSelectedGroupList] = useState(null)
    const [selectedList, setSelectedList] = useState('')
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [subscriptionPaid, setSubscriptionPaid] = useState(false)
    const [templateModal, setTemplateModal] = useState(false)
    const [templatesToShow, setTemplatesToShow] = useState([])
    const [aiTemplatesGenerated, setAiTemplatesGenerated] = useState([])
    const [aiTemplatesGeneratedCount, setAiTemplatesGeneratedCount] = useState(0)
    const [tenantId, setTenantId] = useState('')
    const [tenantData, setTenantData] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [templateMessage, setTemplateMessage] = useState('')
    const [toggleSidebar, setToggleSidebar] = useState(true)
    const [userSettingsModal, setUserSettingsModal] = useState(false)
    const [MFAModal, setMFAModal] = useState(false)
    const [userOptionSelected, setUserOptionSelected] = useState('')
    const [userConfiguration, setUserConfiguration] = useState([])
    const [userChecked, setUserChecked] = useState(false)
    const [userPlan, setUserPlan] = useState('')
    const [updatedPlanMessage, setUpdatedPlanMessage] = useState('')
    const [QRSolicitado, setQRSolicitado] = useState(false)
    const [totalMsgs, setTotalMsgs] = useState('')
    const [totalSMS, setTotalSMS] = useState('')
    const [frequencySelected, setFrequencySelected] = useState('')
    const [removeSchedule, setRemoveSchedule] = useState(false)
    const [showAllPlans, setShowAllPlans] = useState(false)
    const [userStripeData, setUserStripeData] = useState({})
    const [subscriptionId, setSubscriptionId] = useState('')
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(null)
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const [globalEnv, setGlobalEnv] = useState(null)
    const [multiSessionReady, setMultiSessionReady] = useState(false)
    const [multiSessionAuthenticated, setIsMultiSessionAuthenticated] = useState(false)
    const [multiSessionUsuarioEmisor, setMultiSessionUsuarioEmisor] = useState([]) // Array de objetos
    const [multisessionModal, setMultisessionModal] = useState(false)
    const [bannedPhoneModal, setBannedPhoneModal] = useState(false)
    const [bannedTenantModal, setBannedTenantModal] = useState(false)
    const [requestedQRNumber, setRequestedQRNumber] = useState(false)
    const [gettingSessions, setGettingSessions] = useState(false)
    const [sessionClosedModal, setSessionClosedModal] = useState(false)
    const [allTenantContacts, setAllTenantContacts] = useState([])
    const [userInformation, setUserInformation] = useState(null)
    const [whatsappTemplates, setWhatsappTemplates] = useState([])
    const [whatsAPIModalOpen, setWhatsAPIModalOpen] = useState(false)
    const [WAPIEditTemplateModalOpen, setWAPIEditTemplateModalOpen] = useState(false)
    const [userWAPICredentials, setUserWAPICredentials] = useState(null)
    const [WACredentialsModal, setWACredentialsModal] = useState(false)
    const [newIntegrationModalOpen, setNewIntegrationModalOpen] = useState(false)
    const [newExtractionModalOpen, setNewExtractionModalOpen] = useState(false)
    const [wAPIUserInformation, setwAPIUserInformation] = useState([])
    const [integrationsUserInformation, setIntegrationsUserInformation] = useState([])
    const [executedExtractionsHistory, setExecutedExtractionsHistory] = useState([])
    const [extractionData, setExtractionData] = useState({
        type: 'C',
        associatedFields: {
            phone: null, name: null, lastname: null, company: null, position: null, observations: null
        },
        listName: null,
        scheduledExtraction: false,
        sendMessage: false,
        message: null
    })
    const [recurringSelected, setRecurringSelected] = useState('year')
    const [extractionSettings, setExtractionSettings] = useState([])
    const [extractionsByIntegrationId, setExtractionsByIntegrationId] = useState([])
    const [activePlatformCredentials, setActivePlatformCredentials] = useState(null)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [selectedWACredentials, setSelectedWACredentials] = useState(null)
    const [approvedTemplates, setApprovedTemplates] = useState([])
    const [cloudConnectionsList, setCloudConnectionsList] = useState([])
    const [filesListsModalOpen, setFilesListsModalOpen] = useState(false)
    const [cloudFileInformation, setCloudFileInformation] = useState({})
    const [showCloudFileModalOpen, setShowCloudFileModalOpen] = useState(false)
    const [emojiSelect, setEmojiSelect] = useState({ showBox: false })
    const [globalEditorChildren, setGlobalEditorChildren] = useState(null)
    const [archivosImagen, setArchivosImagen] = useState(null)
    const [archivos, setArchivos] = useState(null)
    const [whatsappFilesName, setWhatsappFilesName] = useState([])
    const [remainingTime, setRemainingTime] = useState(0);
    const [switchActiveLog, setSwitchActiveLog] = React.useState({
        checked: false,
    });
    const [listAssingment, setListAssingment] = useState([ '-', 'CardCode', 'CardName', 'CardType', 'GroupCode', 'CmpPrivate', 'Address', 'ZipCode', 'MailAddres', 'MailZipCod', 'Phone1', 'Phone2', 'Fax', 'Notes', 'Balance', 'ExpireDate' ])
    
    // Cancelar SCHEDULE
    useEffect(() => {
        const fetchCancelSchedule = async() => {
            const tenant = user.email.replace('@', '_')
            if(removeSchedule){
                const fechamodificacion = DateTime.now().setZone(timezone);
                const newState = 'canceled'
        
                // Update schedules
                const data = { newState, fechamodificacion, madeByUser }
                await fetchBackend(`cancelar-tareas/${tenant}`, data, 'PUT')
                
                // Get Schedules
                setTimeout(async() => {
                    const getNewSchedule = await fetchBackend(`schedule/${tenant}`);
                    const bodyNewSchedule = await getNewSchedule.json();
                    const dataNewSchedule = await bodyNewSchedule.data;
                    setScheduleToShow(dataNewSchedule);
                    setRemoveSchedule(false);
                }, 2000);
            }
        }

        fetchCancelSchedule();
    }, [removeSchedule])

        
    // Obtiene la información del usuario logueado con WhatsApp
    const getUserInfo = async() => {
        const tenant = user.email.replace('@', '_')
        if(tenant){
            try {
                const BANNED_PHONES = globalEnv.BANNED_PHONES
                const resultado = await fetchBackend( `client-info/${tenant}`, {BANNED_PHONES}, 'POST' )
                const body = await resultado.json();
                const {pushname, wid} = await body.info;
                const { isBannedPhone } = await body;
                if( isBannedPhone && userPlan === 'trial' ){
                    setBannedPhoneModal(true)
                    await blockUserInDatabase(tenant)
                    return
                } else if(isBannedPhone){
                    setBannedPhoneModal(true)
                    return
                } else {
                    setNombreEmisor(pushname)
                    setNumeroEmisor(wid.user)
                    setCodigoQR(false)
                    setQRSolicitado(false)
                    setIsAuthenticated(true)
                }
            } catch (error) {
                console.log('No se pudo acceder a la información del usuario: ' + error)
            }
        }
    }

    const getUserInfo_multiSession = async(tenantid) => {
        const tenant = user.email.replace('@', '_')
        if(tenant){
            try {
                const QRNumber = parseInt(tenantid[tenantid.length - 1])
                const resultado = await fetchBackend( `multisession-client-info/${tenantid}`, 'GET' )
                const body = await resultado.json();
                const {pushname, wid} = await body.info;
                setMultiSessionUsuarioEmisor([...multiSessionUsuarioEmisor, {
                    nombreEmisor: pushname,
                    numeroEmisor: wid.user,
                    ordenUsuario: QRNumber,
                    tenantSession: tenant + QRNumber
                }])
                
                setCodigoQR(false)
                setQRSolicitado(false)
                setIsMultiSessionAuthenticated(true)
            } catch (error) {
                console.log('No se pudo acceder a la información del usuario: ' + error)
            }
        }
    }

    return <ElementContext.Provider value={
        {
          inputList, setInputList,
          message, setMessage,
          selectedList, setSelectedList,
          infoToShow, setInfoToShow,
          modalIsOpen, setModalIsOpen,
          codigoQR, setCodigoQR,
          authenticated, setIsAuthenticated,
          listNames, setListNames,
          confirm, setConfirm,
          confirmModal, setConfirmModal,
          isActive, setIsActive,
          activeList, setActiveList,
          nameActive, setNameActive,
          nombreEmisor, setNombreEmisor,
          numeroEmisor, setNumeroEmisor,
          messagesToShow, setMessagesToShow,
          isDarkMode, setIsDarkMode,
          scheduleModal, setScheduleModal,
          scheduleSMSModal, setScheduleSMSModal,
          scheduleMessage, setScheduleMessage,
          scheduleToShow, setScheduleToShow,
          scheduleExecutedToShow, setScheduleExecutedToShow,
          changesApplied, setChangesApplied,
          templatesToShow, setTemplatesToShow,
          templateModal, setTemplateModal,
          madeByUser, setMadeByUser,
          tenantId, setTenantId,
          tenantData, setTenantData,
          returnToQRModal, setReturnToQRModal,
          confirmRemoveTemplateModal, setConfirmRemoveTemplateModal,
          templateName, setTemplateName,
          templateMessage, setTemplateMessage,
          userChecked, setUserChecked,
          confirmDeleteContactModal, setConfirmDeleteContactModal,
          showInfoModal, setShowInfoModal,
          userPlan, setUserPlan,
          rowSelected, setRowSelected,
          user, // frontegg object
          toggleSidebar, setToggleSidebar,
          selectedGroupList, setSelectedGroupList,
          userSettingsModal, setUserSettingsModal,
          userOptionSelected, setUserOptionSelected,
          userConfiguration, setUserConfiguration,
          delayMsg, setDelayMsg,
          oscillationMsg, setOscillationMsg,
          subscriptionPaid, setSubscriptionPaid,
          updatedPlanMessage, setUpdatedPlanMessage,
          QRSolicitado, setQRSolicitado,
          totalMsgs, setTotalMsgs,
          totalSMS, setTotalSMS,
          frequencySelected, setFrequencySelected,
          removeSchedule, setRemoveSchedule,
          userStripeData, setUserStripeData,
          showAllPlans, setShowAllPlans,
          subscriptionId, setSubscriptionId,
          disclaimerAccepted, setDisclaimerAccepted,
          showDisclaimer, setShowDisclaimer,
          globalEnv, setGlobalEnv,
          multiSessionAuthenticated, setIsMultiSessionAuthenticated,
          multiSessionQR, setMultiSessionQR,
          multiSessionUsuarioEmisor, setMultiSessionUsuarioEmisor,
          multiSessionReady, setMultiSessionReady,
          esMultisesion, setEsMultisesion,
          multisessionModal, setMultisessionModal,
          bannedPhoneModal, setBannedPhoneModal,
          bannedTenantModal, setBannedTenantModal,
          requestedQRNumber, setRequestedQRNumber,
          gettingSessions, setGettingSessions,
          sessionClosedModal, setSessionClosedModal,
          allTenantContacts, setAllTenantContacts,
          userInformation, setUserInformation,
          MFAModal, setMFAModal,
          whatsappTemplates, setWhatsappTemplates,
          whatsAPIModalOpen, setWhatsAPIModalOpen,
          WAPIEditTemplateModalOpen, setWAPIEditTemplateModalOpen,
          userWAPICredentials, setUserWAPICredentials,
          WACredentialsModal, setWACredentialsModal,
          wAPIUserInformation, setwAPIUserInformation,
          confirmDeleteModal, setConfirmDeleteModal,
          selectedWACredentials, setSelectedWACredentials,
          approvedTemplates, setApprovedTemplates,
          cloudConnectionsList, setCloudConnectionsList,
          filesListsModalOpen, setFilesListsModalOpen,
          cloudFileInformation, setCloudFileInformation,
          showCloudFileModalOpen, setShowCloudFileModalOpen,
          aiTemplatesGenerated, setAiTemplatesGenerated,
          aiTemplatesGeneratedCount, setAiTemplatesGeneratedCount,
          emojiSelect, setEmojiSelect,
          globalEditorChildren, setGlobalEditorChildren,
          messageWithTemplate, setMessageWithTemplate,
          newIntegrationModalOpen, setNewIntegrationModalOpen,
          newExtractionModalOpen, setNewExtractionModalOpen,
          integrationsUserInformation, setIntegrationsUserInformation,
          listAssingment, setListAssingment,
          extractionSettings, setExtractionSettings,
          extractionData, setExtractionData,
          activePlatformCredentials, setActivePlatformCredentials,
          executedExtractionsHistory, setExecutedExtractionsHistory,
          recurringSelected, setRecurringSelected,
          extractionsByIntegrationId, setExtractionsByIntegrationId,
          whatsappFilesName, setWhatsappFilesName,
          archivos, setArchivos,
          archivosImagen, setArchivosImagen,
          remainingTime, setRemainingTime,
          switchActiveLog, setSwitchActiveLog
        }
    }>
        {children}
    </ElementContext.Provider>
}