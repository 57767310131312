import { apiBaseUrl } from "./fetch";

let authToken;

const getToken = async({CLIENT_ID, API_KEY}) => {

  const url = 'https://api.frontegg.com/auth/vendor/';
  const options = {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
    body: JSON.stringify({
      clientId: CLIENT_ID,
      secret: API_KEY
    })
  };

  await fetch(url, options)
    .then(res => res.json())
    .then(json => authToken = 'Bearer ' + json.token)
    .catch(err => console.error('error:' + err));
}


const fetchFronteggSignUp = async(data) => {
  await getToken({
    CLIENTID: data.REACT_APP_FRONTEGG_CLIENTID,
    API_KEY: data.REACT_APP_FRONTEGG_API_KEY })

  const url = 'https://api.frontegg.com/identity/resources/users/v1/signUp';
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'frontegg-vendor-host': data.fronteggVendorHost,
      'Content-Type': 'application/json',
      Authorization: authToken
    },
    body: JSON.stringify({
      provider: 'local',
      metadata: 'null',
      name: `${data.name} ${data.lastname}`,
      phoneNumber: data.phone,
      email: data.email,
      companyName: data.company
    })
  };
  
  await fetch(url, options)
              .then(res => res.json())
              .then(json => console.log('Registro exitoso'))
              .catch(err => console.error('error:' + err));
  return
}



const fetchWebhookSignUp = async(data) => {

  const url = `${apiBaseUrl}/api/create-new-user`

  const options = {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: data.email,
      firstname: data.name,
      lastname: data.lastname,
      companyName: data.company,
      country: data.country,
      phone: data.phone,
      clientid: data.CLIENT_ID,
      secret: data.API_KEY,
      frontegg_vendor_host: data.fronteggVendorHost,
      language: data.language,
      socialLogin: data.socialLogin
    })
  };
  
  await fetch(url, options)
    .then(res => console.log('Registro exitoso'))
    .catch(err => console.error('error:' + err));
  return
}


export {
    fetchFronteggSignUp,
    fetchWebhookSignUp
}