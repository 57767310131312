import React, { useState, useEffect, useContext } from 'react';
import Switch from '@mui/material/Switch';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import {ElementContext} from './ElementContext'
import { fetchBackend } from '../helpers/fetch';
import {DateTime} from 'luxon';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)(({ theme }) => ({
    padding: 0,
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    size: "small",
    width: "250px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
}));

const LogsAdmin = () => {

    const { remainingTime, setRemainingTime, switchActiveLog, setSwitchActiveLog } = useContext(ElementContext);
    const [logContent, setLogContent] = useState("");
    const [copied, setCopied] = useState(false);
    const [time, setTime] = useState("");

    const handleChange = (event) => {
        setSwitchActiveLog({ ...switchActiveLog, [event.target.name]: event.target.checked });
    };
    
    const handleChangeTimeActivation = (event) => {
        const value = event.target.value;
        setTime(value);
        localStorage.setItem('selectedTime', value);
    };
    
    const handleBlurTimeActivation = () => {
        if (time < 5) {
            setTime(5);
        } else if (time > 1440) {
            setTime(1440);
        }
    };

    useEffect(() => {
        const savedTime = localStorage.getItem('remainingTime');
        const savedChecked = localStorage.getItem('isChecked') === 'true';
        const savedSelectedTime = localStorage.getItem('selectedTime');

        if (savedTime) {
            setRemainingTime(parseInt(savedTime, 10));
        }
    
        if (savedChecked) {
            setSwitchActiveLog(prevState => ({ ...prevState, checked: savedChecked }));
        }

        if (savedSelectedTime) {
            setTime(savedSelectedTime);
        }

    }, []);
    
    // Contador de segundos decreciente

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime(prevTime => {
                    const newTime = prevTime - 1;
                    localStorage.setItem('remainingTime', newTime);
    
                    if (newTime <= 0) {
                        clearInterval(timer);
                        setSwitchActiveLog({ ...switchActiveLog, checked: false });
                        localStorage.setItem('isChecked', false);
                        return 0;
                    }
    
                    return newTime;
                });
            }, 1000); // Actualiza cada segundo
    
            return () => clearInterval(timer);
        }
    }, [remainingTime]);

    useEffect(() => {
        const fetchLogStatus = async () => {
            try {
                const response = await fetchBackend('log-status', 'GET');
                const data = await response.json();
                setSwitchActiveLog(prev => ({ ...prev, checked: data.logsActivated }));
            } catch (error) {
                console.error("Error al obtener el estado de logs:", error);
            }
        };
        fetchLogStatus();
    }, []);

    const handleActivateLogs = async (isChecked) => {

        if (isChecked) {
            const timeInSeconds = time * 60; // Convertir horas a segundos para la prueba
            setRemainingTime(timeInSeconds);
            localStorage.setItem('remainingTime', timeInSeconds);
            localStorage.setItem('isChecked', true);
        } else {
            setRemainingTime(0);
            localStorage.removeItem('remainingTime');
            localStorage.setItem('isChecked', false);
        }
    
        setSwitchActiveLog({ ...switchActiveLog, checked: isChecked });

        // Activar logs en el backend
        const resultado = await fetchBackend('activate-log', { isChecked, time }, 'POST');
        // console.log(await resultado.text());
    
        // Refrescar logs con la cantidad de líneas seleccionadas
        handleRefreshLogs();
    };

    const handleRefreshLogs = async () => {
        try {
            const response = await fetchBackend('refresh-log', 'GET');
    
            if (!response.ok) {
                const errorMessage = await response.text();
                // console.error("Error al obtener el log:", errorMessage);
                setLogContent("Error al cargar el log");
                return;
            }
    
            const logText = await response.text(); // Obtener el texto del log
            setLogContent(logText); // Actualizar el estado
        } catch (error) {
            // console.error("Error en la solicitud:", error);
            setLogContent("Error al cargar el log");
        }

    };

    const handleDownloadLogs = async () => {
        try {
            const response = await fetchBackend('download-log', 'GET');
    
            if (!response.ok) {
                const errorMessage = await response.text();
                // console.error("Error al descargar:", errorMessage);
                return;
            }
    
            // Convertir la respuesta a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
    
            // Crear un enlace temporal para la descarga
            const a = document.createElement('a');
            a.href = url;
            a.download = "admin.log"; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
    
            // Limpiar el URL Object
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error en la descarga:", error);
        }
    };
    

    const handleCopyLogs = async () => {
        try {
            await navigator.clipboard.writeText(logContent);

            setCopied(true); 

            // Volver al icono original después de 2 segundos
            setTimeout(() => setCopied(false), 2000);
        } catch (error) {
            console.error("Error al copiar el log:", error);
        }
    };
    

    const handleDeleteLogs = async () => {
        try {
            const response = await fetchBackend('delete-log', 'GET');

            if (!response.ok) {
                const errorMessage = await response.text();
                console.error("Error al eliminar el log:", errorMessage);
                alert("Error al eliminar el log");
                return;
            }

            console.log("Log eliminado correctamente");

            // Opcional: Actualizar el estado para que desaparezca el contenido en la pantalla
            setLogContent("");
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const { t } = useTranslation();

    const scrollbarStyles = `
        ::-webkit-scrollbar {
            width: 5px;
        }
        ::-webkit-scrollbar-track {
            background: rgb(56, 56, 56);
        }
    `;

    const formatTime = (minutes) => {
        if (minutes >= 60) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} ${t('hours')} ${remainingMinutes > 0 ? `${remainingMinutes} ${t('minutes')}` : ''}`;
        }
        return `${minutes} ${t('minutes')}`;
    };

    return (
        <div className='home col-12 animate__animated animate__fadeIn px-2'>
            <div className='container-fluid d-flex flex-column px-1' style={{background: "#ffffff"}}  >
                <p className="text" style={{ marginBottom: "0px" }}>{t('consoleLog')}</p>
                <p className="subtitle" style={{ marginBottom: "15px" }}>{t('titleConsoleLog')}</p>
            </div>

            <div className='d-flex flex-column justify-content-between align-items-center'>
                <div  className='d-flex align-items-center justify-content-between' style={{background: "#f6f6f6", border: "1px solid #767676", height: "45px", width: "100%", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <div className='d-flex items-center justify-content-center px-3'>
                        <Icon icon="weui:setting-outlined" width="24" height="24"  style="color: #777" />
                        <p style={{ color: "#777777", margin: "0px", marginLeft: "10px" }}>{t('settingLog')}</p>
                    </div>
                </div>

                <div className="d-flex flex-column" style={{background: "#8u8u8u", width: "100%", border: "1px solid #dbdbdb", borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}>
                    <p className="pt-2" style={{ color: "#777777", margin: "0px", marginLeft: "10px", marginBottom: "10px", fontSize: '14px'}}>{t('timeLogs')} 
                        <span style={{ color: "#f55142", fontSize: '11px', marginLeft: "10px"}}>{t('timeLogsDescription')}</span>
                    </p>
                    {/* <FormControlStyled variant="outlined">
                            <StyledInputLabel htmlFor="outlined-age-native-simple">{t('timeActive')}</StyledInputLabel>
                            <StyledSelect
                            native
                            value={time}
                            onChange={handleChangeTimeActivation}
                            label={t('timeActive')}
                            inputProps={{
                                name: 'tiempo',
                                id: 'outlined-age-native-simple',
                            }}
                            >
                            <option aria-label="None" value="" />
                            <option value={1}>1 {t('hourLog')}</option>
                            <option value={6}>6 {t('hoursLog')}</option>
                            <option value={12}>12 {t('hoursLog')}</option>
                            <option value={24}>24 {t('hoursLog')}</option>
                            <option value={48}>48 {t('hoursLog')}</option>
                            </StyledSelect>
                    </FormControlStyled> */}

                    <StyledTextField
                        id="outlined-basic"
                        variant="outlined"
                        value={time}
                        onChange={handleChangeTimeActivation}
                        onBlur={handleBlurTimeActivation}
                        label={t('timeActive')}
                        size="small"
                        inputProps={{
                            name: 'tiempo',
                            id: 'outlined-basic',
                            min: 5,
                            max: 1440,
                            type: 'number'
                        }}
                    />
                    

                    <div className='d-flex align-items-center' style={{ marginLeft: "10px" }}>
                        <label htmlFor="switch-active" style={{ color: "#777777", margin: "0px", marginRight: "10px", fontSize: '14px' }}>
                        {t('activateLog')}
                        </label>
                        <Switch
                            checked={switchActiveLog.checked}
                            onChange={(event) =>{
                                handleChange(event);
                                handleActivateLogs(event.target.checked);
                            }}
                            color="primary"
                            name="checked"
                            id="switch-active"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={!time && !switchActiveLog.checked}
                        />
                        <div className='d-flex align-items-center' >
                            {switchActiveLog.checked && <p style={{ color: "#777777", margin: "0px", fontSize: '11px' }}>{t('selectedTime')}: {formatTime(time)}</p>}
                        </div>

                    </div>

                    {switchActiveLog.checked && (
                        <>
                            <p style={{ color: "#777777", margin: "0px", marginLeft: "10px", fontSize: '14px'}}>{t('actionsLogs')}</p>
                            <div className='d-flex align-items-center' style={{ marginLeft: "5px" }}>

                                <StyledButton
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<Icon icon="tabler:refresh" width="23" height="23"  style="color: #777" />}
                                    onClick={handleRefreshLogs}
                                    sx={{ fontSize: '12px' }}
                                >
                                    {t('refresh')}
                                </StyledButton>
                                
                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Icon icon="material-symbols:download-rounded" width="24" height="24"  style="color: #777" />}
                                    onClick={handleDownloadLogs}
                                    sx={{ fontSize: '12px' }}
                                >
                                    {t('downloadLogs')}
                                </StyledButton>

                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    startIcon={
                                        copied ? (
                                            <Icon icon="material-symbols:check-rounded" width="24" height="24" style="color: #777"  />
                                        ) : (
                                            <Icon icon="uil:copy" width="24" height="24" style="color: #777"  />
                                        )
                                    }
                                    onClick={handleCopyLogs}
                                    sx={{ fontSize: '12px' }}
                                >
                                    {copied ? t('copyState') : t('copyLogs')}
                                </StyledButton>

                                {/* <StyledButton
                                    variant="outlined"
                                    color="error"
                                    startIcon={<Icon icon="mdi:trash-outline" width="24" height="24"  style="color: #777" />}
                                    onClick={handleDeleteLogs}
                                    sx={{ fontSize: '12px' }}
                                >
                                    {t('deleteLogs')}
                                </StyledButton> */}
                            </div>
                        </>
                    )}
                </div>
            </div>
            
            {switchActiveLog.checked && (
                <div className='p-3 d-flex justify-content-between align-items-center' style={{background: "#ffffff", border: "2px solid #000000", backgroundColor: "#2c2c2c", marginTop: "15px", borderRadius: "5px"}}  >
                    <div style={{
                        height: "430px",
                        width: "100%",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1"
                    }}>
                        <style>
                            {scrollbarStyles}
                        </style>
                        <p style={{ color: "#ffffff", fontSize: '12px' }}>{t('logsEnabled')}</p>
                        <pre style={{ color: "#ffffff", fontSize: "12px", whiteSpace: "pre-wrap" }}>{logContent}</pre>
                    </div>
                </div>
            )}

            <div style={{height: "20px", width: "100%", background: "#ffffff"}}>
            </div>
        </div>
    );
};

export default LogsAdmin;



    