import React, {useContext} from 'react';
import {ElementContext} from './ElementContext'
import Sidebar from './Sidebar';
import Home from './Home';
import ListsManagement from './ListsManagement';
import Historial from './Historial';
import ScheduleDone from './ScheduleDone';
import { Navigate, Route, Routes } from "react-router-dom";
import GestionPlantillas from './GestionPlantillas';
import CheckValidations from './CheckValidations';
import UserBoxInfo from './UserBoxInfo';
import ThankYouScreen from './ThankYouScreen';
import { useEffect } from 'react';
import UserSettingsModal from './modals/UserSettingsModal';
import UpdatePlanScreen from './UpdatePlanScreen';
import { useTranslation } from 'react-i18next';
import CancelSubscriptionScreen from './CancelSubscriptionScreen';
import FormularioSMS from './FormularioSMS';
import FormularioWhatsApp from './FormularioWhatsApp';
import { fetchBackend } from '../helpers/fetch';
import FormularioWhatsAppMultisesion from './FormularioWhatsAppMultisesion';
import WhatsappAPITemplates from './whatsappAPICloud/WhatsappAPITemplates';
import WhatsappAPIMessages from './whatsappAPICloud/WhatsappAPIMessages';
import WhatsappAPICredentials from './whatsappAPICloud/WhatsappAPICredentials';
import IntegrationsMainPage from './integrations/IntegrationsMainPage';
import CloudConnections from './filesLists/CloudConnections';
import WhatsappMessageGenerator from './WhatsappMessageGenerator';
import LogsAdmin from './LogsAdmin';
import ExtractionsScreen from './integrations/ExtractionsScreen';
import { useAuth } from '@frontegg/react';
import ExecutedExtractionsHistory from './integrations/ExecutedExtractionsHistory';
import ExecutedPaymentTracking from './ui/PaymentTracking';
import '../styles/ag-grid-style.css'


const AppRouter = () => {
  
  const { userChecked, updatedPlanMessage, setUpdatedPlanMessage, userPlan, globalEnv, userConfiguration } = useContext(ElementContext)
  const { t } = useTranslation();
  const { user } = useAuth();

  // console.log('user', user)

  const deleteOldSubscription = async() => {
    const subscriptionID =  localStorage.getItem('subscriptionID')
    // const userIsoCode =  localStorage.getItem('userIsoCode')
    // const isArgentinianUser = userIsoCode === 'AR'
    
    const unsubscriptionData = {
      email: await user.email,
      subscriptionID,
      reasonsData: ['subscription change'],
      otherReasonData: null,
      isUpdate: true,
      userConfiguration
    }

    if(subscriptionID){
      await fetchBackend('delete-subscription', {unsubscriptionData}, 'DELETE');
      // if(isArgentinianUser){
      //   await fetchBackend(`delete-mp-subscription/${subscriptionID}`, {unsubscriptionData}, 'DELETE');
      // } else {
      //   await fetchBackend('delete-subscription', {unsubscriptionData}, 'DELETE');
      // }
    }

    localStorage.removeItem('subscriptionID')
  }

  // A la escucha de cambios en la URL
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setUpdatedPlanMessage( t('paymentSucces') );
      deleteOldSubscription()
    }
    if (query.get("canceled")) {
      setUpdatedPlanMessage( t('paymentCanceled') );
    }
  }, []);


  return (
      <div>
        {
          userChecked ? (
            <div>
              <Sidebar/>
              
              <Routes>
                  <Route exact path='/' element={ <Home /> }/>
                  {
                    globalEnv.SIDEBAR_MENU.SEND_MESSAGES.includes(userPlan) && (
                      <Route exact path='/formulario-whatsapp' element={ <FormularioWhatsApp/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.SEND_SMS.includes(userPlan) && (
                      <Route exact path='/formulario-sms' element={ <FormularioSMS/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.CONTACT_LISTS.includes(userPlan) && (
                      <Route exact path='/mis-listas' element={ <ListsManagement/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.HISTORY_MESSAGES.includes(userPlan) && (
                      <Route exact path='/historico' element={ <Historial/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.SCHEDULE_MESSAGES.includes(userPlan) && (
                      <Route exact path='/programados' element={ <ScheduleDone/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.TEMPLATES_MESSAGES.includes(userPlan) && (
                      <Route exact path='/plantillas' element={ <GestionPlantillas/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.FILE_LISTS.includes(userPlan) && (
                      <Route exact path='/cloud-connections' element={ <CloudConnections/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.MULTISESSION_MESSAGES.includes(userPlan) && (
                      <Route exact path='/formulario-multisesion' element={ <FormularioWhatsAppMultisesion/> } />
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.AI_TEMPLATES.includes(userPlan) && (
                      <Route exact path='/message-generator' element={ <WhatsappMessageGenerator/> } />
                    )
                  }

                  {
                    globalEnv.SIDEBAR_MENU.LOGS_ADMIN.includes(userPlan) && (
                      <Route exact path='/logs-admin' element={ <LogsAdmin/> } />
                    )
                  }


                  {/* {
                    globalEnv.SIDEBAR_MENU.INTEGRATIONS.includes(userPlan) && (
                      <Route exact path='/platform-integrations' element={ <IntegrationsMainPage/> }/>
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.INTEGRATIONS.includes(userPlan) && (
                      <Route path='/platform-extractions/sapb1' element={ <ExtractionsScreen/> } />
                    )
                  }
                  {
                    globalEnv.SIDEBAR_MENU.INTEGRATIONS.includes(userPlan) && (
                      <Route path='/extractions-history' element={ <ExecutedExtractionsHistory/> } />
                    )
                  } */}

                  {
                    globalEnv.SIDEBAR_MENU.WHATSAPP_API.includes(userPlan) && (
                      <>
                        <Route exact path='/whatsapp-api-credentials'
                               element={ <WhatsappAPICredentials/> }/>
                        <Route exact path='/whatsapp-api-templates'
                               element={ <WhatsappAPITemplates/> }/>
                        <Route exact path='/send-template-message'
                               element={ <WhatsappAPIMessages/> }/>
                      </>
                    )
                  }
                  <Route path='/powerbi-table' element={ <ExecutedPaymentTracking/>} />
                  <Route exact path='/baja' element={ <CancelSubscriptionScreen/> }/>
                  <Route exact path='/cambio-de-plan' element={ <UpdatePlanScreen/> }/>
                  <Route path='*' element={<Navigate to='/' replace/>} />
              </Routes>

              <UserBoxInfo />
              <UserSettingsModal />
            </div>
          ) : (
          <div>
              {
                updatedPlanMessage ? (
                  <ThankYouScreen />
                ) : (
                  <CheckValidations />
                )
              }
          </div>
          )
        }
      </div>
  )
};

export default AppRouter;
